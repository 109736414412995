
import React, { useMemo } from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { Container, Flex, Heading, Box, Img, VStack } from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import { getUrlFromOldToNewBucket } from "../../helpers";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import usePrismicNews from "../../hooks/usePrismicNews";
import RichText from "../../components/PrismicRichText";
import UsefulLinks from "../../components/usefulLinks";

const SingleNews = () => {
  const { language } = useTranslation();
  const [id] = useQueryParam("id", StringParam);
  const data = usePrismicNews();
  const news = useMemo(() => data.news.find((item) => item.id === id), [id]);

  return (
    <Main>
      <Container maxW="7xl" px="5" mb="3.75rem">
        <Seo
          title={data.title}
          description={data.title}
          link={`https://www.fsm.am/${language}/events`}
        />
        <SmallNav>{news?.title}</SmallNav>
        
        <Flex mt={{ base: "3", sm: "5" }} bg="gray.100" p="5" borderRadius="8px" mb="3.75rem">
  {/* Левая часть: Heading и текст новости */}
  <Box flex="1" mr="10" style={{ textAlign: "justify" }}>
    <Heading fontSize="3xl" mb="5" textAlign="center" color="green.100">
      {news?.title}
    </Heading>
    <RichText text={news?.content} style={{ textAlign: "justify" }} />
  </Box>
  
  {/* Правая часть: Изображение новости */}
  <Box flex="1" display="flex" alignItems="center">
    {(news?.imageBig || news?.image) && (
      <Img
        objectFit="cover"
        borderRadius="8px"
        width="600px"
        height="400px"
        src={getUrlFromOldToNewBucket(news.imageBig || news.image)}
        alt={news.title}
      />
    )}
  </Box>
</Flex>



        {/* Ваши другие компоненты или блоки кода */}
      </Container>
    </Main>
  );
};

export default SingleNews;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;






